import { LoadingButton } from "@mui/lab"
import { Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";

interface ILoadingButton {
    children: string | JSX.Element
    onClick: any
    disabled?: boolean
    variant?: "contained" | "text" | "outlined"
    sx?: Record<string, unknown>
    dataTest?: string
    helperText?: string
}

interface ILoadingFormButton extends ILoadingButton {
    triggerClick?: boolean
    setTriggerClick?: React.Dispatch<React.SetStateAction<boolean>>
}

interface IHtmlLoadingFormButton extends ILoadingButton {
    loading: boolean
}

export const LoadingFormButton = (props: ILoadingFormButton): JSX.Element => {
    const { onClick: handleSubmit, triggerClick, setTriggerClick, ...htmlProps } = props;
    const [ isSubmitting, setIsSubmitting ] = useState(triggerClick || false);
    const mounted = useRef(false);

    useEffect(() => {
        mounted.current = true;
        return () => { mounted.current = false }
    }, []);

    useEffect(() => {
        if (triggerClick) {
            onClick().then(() => setTriggerClick && setTriggerClick(false))
        }
    }, [triggerClick]);

    const setIsSubmittingToFalseInAWhile = (): void => {
        setTimeout(() => {
            if (mounted.current) {
                setIsSubmitting(false)
            }    
        }, 1000)
    };

    const onClick = async (values?: any): Promise<void> => {
        setIsSubmitting(true)
        handleSubmit(values).then(val => {
            if (mounted.current && val) {
                setIsSubmitting(false)
            }
            else if (mounted.current) {
                setIsSubmittingToFalseInAWhile()
            }
        })
        .catch((err) => {
            console.log('error submitting:')
            console.log(err)
            if (mounted.current) {
                setIsSubmittingToFalseInAWhile()
            }    
        })
    }
    return HtmlLoadingFormButton({ ...htmlProps, onClick, loading: isSubmitting })
}

const HtmlLoadingFormButton = (
    { children, disabled, onClick, loading, variant, sx, dataTest, helperText }: IHtmlLoadingFormButton
    ): JSX.Element => 
    <>
    {/* <Grid container>
        <Grid sm={12} item> */}
            <LoadingButton
                fullWidth
                data-test={dataTest}
                disabled={disabled}
                variant={variant}
                sx={sx}
                onClick={onClick}
                loading={loading}
            >
                {children}
            </LoadingButton>
            {helperText && <Typography color="red" data-test={dataTest ? `${dataTest}-helper-text` : undefined}>{helperText}</Typography>}
        {/* </Grid>
    </Grid> */}
    </>
LoadingFormButton.defaultProps = {
    variant: "contained",
    // sx: { padding:'1vh', marginTop:'16px', marginBottom:'8px'},
    sx: {},
    disabled: false,
    dataTest: 'submit'
}

HtmlLoadingFormButton.defaultProps = LoadingFormButton.defaultProps