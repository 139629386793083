import { useEffect } from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useUser } from "../_hooks/useUser";

// export const LogoutPage = (): JSX.Element => {
//     const { user, mutate, loggedOut, loading } = useUser(true);

//     useEffect(() => {
//         console.log('mutating in logout page')
//         mutate()
//         // setTimeout(() => {
//         //     if (notYetLoggedOut)
//         //         mutate();
//         //         console.log({ user, loggedOut, loading })
//         // }, 100)
//         // return () => { notYetLoggedOut = false };
//     }, [loggedOut, loading, user]);

//     // useEffect(() => {
//     //     console.log('in logout page')
//     //     console.log({loading, loggedOut, user})
//     //     // window.location.reload()
//     //     // mutate();
//     //         // browserHistory.replace("/", {signedOut: true} );
//     //         // console.log({loading, user, loggedOut})
//     //     // browserHistory.replace("/login");
//     // }, [loggedOut, loading, user])
//     // // console.log({loading, loggedOut, user})
//     return (
//         <Box sx={{ display: 'flex', justifyContent:'center', height: '100vh', alignItems:'center' }}>
//             <Typography variant='h5' mr='16px'>Loggar ut...</Typography>
//         </Box>
//     )

//     // return ( 
//     // <div onClick={() => {
//     //     console.log(user)
//     // }}>loggar ut...</div>
//     // )
// }

export const LogoutPage = (): JSX.Element =>
    <Box sx={{ display: 'flex', justifyContent:'center', height: '100vh', alignItems:'center' }}>
        <Typography variant='h5' mr='16px'>Loggar ut...</Typography>
    </Box>