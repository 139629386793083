import axios, { AxiosRequestHeaders } from "axios";
import {
    Email, License, OrderInputProps, Respondent, Form, User, 
    Part, OrderProps, RespondentInput, EmailInput, ClientStatus 
} from "../_interfaces";
import { gqlRequest } from "./gqlRequests";

export const api = () : {
    sendOrderForm: (values: any) => Promise<{ path?: string, error?: string }>
    sendEmailForm: (values: EmailInput) => Promise<Email>
    sendAccountForm: (user: User) => Promise<User>
    sendChangeEmailAddressForm: (newEmail: string) => Promise<{ status?: string, errors?: string[] }>
    sendChangePasswordForm: (newPassword: string, oldPassword: string) => Promise<{ status?: string, errors?: string[] }>
    sendChooseRespondentsForm: (respondents: RespondentInput[]) => Promise<Respondent[]>
    getOrders: () => Promise<OrderProps[]>
    sendEmail: (type: string, address: string, order: OrderProps) => Promise<string>
    getEmails: (license: License) => Promise<Email[]>
    getForm: ({ license, link }: { license?: License, link?: string }) => Promise<Form>
    sendTestEmail: (email:Email) => Promise<string[]>
    getLicenses: () => Promise<License[]>
    newUser: (user: User) => Promise<User>
    newEmail: (email: Email, license: License) => Promise<Email>
    newLicense: (license: License) => Promise<License>
    sendAnswers: (answers: any) => Promise<Part[]>
    getClientStatus: () => Promise<ClientStatus & { errors?: string[] }>
    addRespondent: (respondent: Respondent) => Promise<Respondent & { errors?: string[] }>
    editRespondent: (
        { oldValues, newValues, order }:
        { oldValues: Respondent, newValues: Respondent, order: OrderProps }
    ) => Promise<Respondent>
    editOrder: (
        { newOrder, oldOrder }:
        { newOrder: OrderProps, oldOrder: OrderProps }
    ) => Promise<OrderProps>
    sendLicenseOrder: ( values: { key: string, pcs: number }[] ) => Promise<{ key: string, pcs: number }[]>
    generateReport: ( order: OrderProps) => Promise<string>
    downloadReport: (filename: string, clientName: string) => Promise<void>
    confirmNewEmail: ( link: string ) => Promise<{ status?: string, errors?: string[] }>
    confirmPasswordChange: ( link: string, password: string ) => Promise<{ status?: string, errors?: string[] }>
    forgottenPassword: ( email: string ) => Promise<any>
 } => {

        const apiUrl = process.env.REACT_APP_API_URL;
        if (apiUrl === undefined) {
            throw new Error('url to api is not set at apiServices');
        }

        const fetcher = async <T>({query, variables, headers, url}: { query: string, url?: string, variables?: object, headers?: AxiosRequestHeaders }): Promise<T> =>
            axios.post(
                `${apiUrl}/${url || 'graphql'}`,
                { query: gqlRequest[query], variables },
                { headers: headers || { "Content-Type": "application/json" } }
            )
            .then(({data: { data, errors }, status}) => {
                if (status === 200) {
                    if (errors) {
                        return { errors: errors.map(e => e.message), ...data[query] }
                    }
                    return data[query]
                }
                throw new Error(`Request failed with status ${status}`)
            })
            .catch((error: Error) => {
                console.error('server response returned with errors:')
                console.error(error)
                const e = { ...error } as any;
                e.status = 403;
                throw e;
            });

        const handleError = (error: unknown, message?: string): never => {
            throw new Error(JSON.stringify(error))
        }

        const forgottenPassword = (email: string): Promise<any> =>
            fetcher<any>({ query: 'resetPassword', variables: { email }, url: 'update' })
            .catch(error => handleError(error, 'forgottenPassword'))

        const confirmNewEmail = ( link: string ): Promise<{ status?: string, errors?: string[] }> =>
            fetcher<{ errors?: string[], status?: string }>({ url: 'update', query: 'confirmEmailChange', variables: { link } })
            .catch(error => handleError(error, 'confirmNewEmail'));

        const confirmPasswordChange = ( link: string, password: string ): Promise<{ status?: string, errors?: string[] }> =>
            fetcher<{ errors?: string[], status?: string }>({ url: 'update', query: 'confirmPasswordChange', variables: { link, password } })
            .catch(error => handleError(error, 'confirmNewPassword'));

        const generateReport = ({ respondents, client: c, ended, started, languageOptions, ...order }: OrderProps): Promise<string> =>
            fetcher<string>({ query: 'report', variables: { order } })
            .catch(error => handleError(error, 'getReport'))

        const sendChangeEmailAddressForm = async (newEmail: string): Promise<{ status?: string, errors?: string[] }> =>
        fetcher<{ errors?: string[], status?: string }> ({
                query: 'changeEmailAddress',
                variables: { newEmail }})
                .catch(error => handleError(error, 'sendChangeEmailAddressForm'));

        const sendChangePasswordForm = async (newPassword: string, oldPassword: string): Promise<{ status?: string, errors?: string[] }> =>
            fetcher<{ errors?: string[], status?: string }> ({
                query: 'changePassword',
                variables: { newPassword, oldPassword }})
                .catch(error => handleError(error, 'sendChangePasswordForm'))
            
        const sendLicenseOrder = async ( values: { key: string, pcs: number }[] ): Promise<{ key: string, pcs: number }[]> =>
            fetcher<{ key: string, pcs: number }[]>({
                query: 'placeLicenseOrder',
                variables: { values }
            })
        

        const sendOrderForm = async (values: OrderInputProps): Promise<{ path?: string, error?: string }> =>
            fetcher<{path?: string, error?: string}>({ query: 'placeOrder', variables: { values }})
            .catch(error => handleError(error, 'sendOrderForm'))

        const sendAccountForm = async ({ confirmPassword, ...values }: User) : Promise<User> => 
            fetcher<User>({
                query: 'editUser',
                variables: { values },
            })
            .catch(error => handleError(error, 'sendAccountForm'));

        const sendEmailForm = async ({ placeholders, ...values }: EmailInput): Promise<Email> =>
            fetcher<Email>({ query: 'editEmail', variables: { values }})
            .catch(error => handleError(error, 'sendEmailForm'))

        const sendChooseRespondentsForm = async (respondents: RespondentInput[]): Promise<Respondent[]> =>
            fetcher<Respondent[]>({ query: 'setRespondents', variables: { respondents }})
            .catch(error => handleError(error, 'sendChooseRespondentsForm'))

        const addRespondent = async (respondent: RespondentInput): Promise<Respondent & { errors?: string[] }> =>
            fetcher< Respondent & { errors?: string[] }>({ query: 'addRespondent', variables: { respondent }})
            .catch(error => handleError(error, 'addRespondent'))

        const getClientStatus = async (): Promise<ClientStatus & { errors?: string[] }> =>
            fetcher<ClientStatus & { errors?: string[] }>({ query: 'getClientStatus' })
            .catch(error => handleError(error, 'getClientStatus'))

        const downloadReport = (filename: string, clientName: string): Promise<void> =>
            axios({
                url: `${apiUrl}/download?filename=${filename}`,
                method: 'POST',
                responseType: 'blob',
                headers: { "Content-Type": "application/pdf" }                
            }).then( response => {
                if (response.data) {
                    const href = URL.createObjectURL(response.data);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', `360-${clientName.replace(' ', '_')}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);    
                }
            });

        const getEmails = async (license: License): Promise<Email[]> => 
            fetcher<Email[]>({ query: 'emails', variables: { license } })
            .catch(error => handleError(error, 'getEmails'))

        const getForm = async ({ license, link }: { license?: License, link?: string }) : Promise<Form> =>
            fetcher<Form>({ query: 'forms', variables: { license, link } })
            .catch(error => handleError(error, 'getForms'))

        /**
         * Returns OrderProps except answers..
         * @returns 
         */
        const getOrders = async () : Promise<OrderProps[]> =>
            fetcher<OrderProps[]>({ query: 'orders' })
            .catch(error => handleError(error, 'getOrders'))

        const sendEmail = async (type: string, to: string, order: OrderProps) : Promise<string> => 
            fetcher<string>({
                query: 'sendEmail',
                variables: { type, to, order: { id: order.id, email: order.client.email }}
                })
            .catch(error => handleError(error, 'sendEmail'))

        // errorS?
        const sendTestEmail = async (email: Email) : Promise<string[]> =>
                fetcher<string[]>({ query: 'testEmail', variables: { email }})
                .catch(error => handleError(error, 'sendTestEmail'))

        const newUser = async (user: User): Promise<User> => 
            fetcher<User>({ query: 'addUser', variables: { user } })
            .catch(error => handleError(error, 'tryNewUser'))

        const newEmail = async (email: Email, license: License): Promise<Email> =>
            fetcher<Email>({ query: 'addEmail', variables: { email, license } })
            .catch(error => handleError(error, 'tryNewEmail'));

        const getLicenses = async (): Promise<License[]> =>
            fetcher<License[]>({ query: 'allLicenses' })
            .catch(error => handleError(error, 'getLicenses'))

        const newLicense = async ({ logo, ...license }: License): Promise<License> => {

            const formData = new FormData();
            formData.append('query', gqlRequest.addLicense);
            // formData.append('variables', JSON.stringify({ license }));
            formData.append('variables', JSON.stringify({ license: { ...license, discountPrice: -1 } }));
            formData.append('file', logo as File);

            const response = await axios.post(
                `${apiUrl}/upload`,
                formData,
                { headers: { "Content-Type": "multipart/form-data" } }
            );
            const { data: { data: { addLicense }, error } } = response;

            if (error) {
                handleError(error, 'newLicense')
            }
            return addLicense;
        }

        const sendAnswers = async({ file, ...answers }: any): Promise<Part[]> => {
            const formData = new FormData();
            formData.append('query', gqlRequest.handInForm);
            formData.append('variables', JSON.stringify({ data: answers }));
            formData.append('file', file as File);

            const response = await axios.post(
                `${apiUrl}/upload`,
                formData,
                { headers: { "Content-Type": "multipart/form-data" } }
            );
            const { data: { data: { handInForm }, errors } } = response;
            if (errors) {
                handleError(errors, 'handInForm')
            }
            return handInForm;
        }

        const editRespondent = async ({ oldValues, newValues, order: { answers, respondents, client, started, languageOptions, ...order } }: {
            oldValues: Respondent,
            newValues: Respondent,
            order: OrderProps
        }): Promise<Respondent> =>
            fetcher<Respondent>({
                query: 'editRespondent',
                variables: { oldValues, newValues, order }
            })
            .catch(error => handleError(error, 'editRespondent'))

        const editOrder = async ({ newOrder, oldOrder: { answers, respondents, started, languageOptions, ...oldOrder } }: { 
            newOrder: OrderProps, 
            oldOrder: OrderProps
        }): Promise<OrderProps> =>
            fetcher<OrderProps>({ query: 'editOrder', variables: { newOrder, oldOrder } })
            .catch(error => handleError(error, 'editOrder'))
    
    return {
        sendOrderForm,
        sendEmailForm,
        sendAccountForm,
        sendChangeEmailAddressForm,
        sendChangePasswordForm,
        sendChooseRespondentsForm,
        getOrders,
        sendEmail,
        sendTestEmail,
        getEmails,
        getForm,
        getLicenses,
        newUser,
        newEmail,
        newLicense,
        sendAnswers,
        getClientStatus,
        addRespondent,
        editRespondent,
        editOrder,
        generateReport,
        downloadReport,
        sendLicenseOrder,
        confirmNewEmail,
        confirmPasswordChange,
        forgottenPassword
    }
}