import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { SLink } from "../_components/SLink";
import { api } from "../_services/apiServices";
import browserHistory from "../_helpers/History";

export interface PagePresenterInterface {
    location: {
      search: string
      pathname: string
      state: any
    }
  }
  
// export const ConfirmationPage = ({ location }: PagePresenterInterface): JSX.Element => {
export const ConfirmationPage = ({ location }: { location: { search: string, pathname: string, state: any }}): JSX.Element => {

    const [countdown, setCountdown] = useState(10);
    const [status, updateStatus] = useState('pending')

    // const change = location.pathname.substring(1).split('/')[1]
    const [key, value] = location.search.substring(1).split('=')

    useEffect(() => {
        let mounted = true;
        if (key === 'link') {
            // if (change === 'email') {
                api().confirmNewEmail(value)
                .then( ({ status: responseStatus, errors }) => {
                    setCountdown(responseStatus === 'ok' ? 10 : 30)
                    setTimeout(() => mounted && updateStatus(responseStatus || ''), 1000);
                })
            // }
            // if (change === 'password') {
            //     api().confirmPasswordChange(value)
            //     .then( ({ status: responseStatus, errors }) => {
            //         setCountdown(responseStatus === 'ok' ? 5 : 30)
            //         setTimeout(() => mounted && updateStatus(responseStatus || ''), 1000);
            //     })
            // }
        }
        return () => { mounted = false }
    }, []);

    useEffect(() => {
        let mounted = true;
        setTimeout(() => mounted && setCountdown(countdown - 1), 1000);
        if (mounted && countdown === -1) {
            browserHistory.push('/logout')
        }
        return () => { mounted = false }
    }, [status, countdown])

    const text = (): { msg: string, redir?: string} => {
        if (status === 'pending') {
            return { msg: 'Jobbar...' }
        }
        if (status === 'ok') {
            return { msg: `Din epost-adress har uppdaterats.`, redir: `Vidarebefordrar om ${countdown}`}
        }
        return { msg: `
        Kunde ej byta epostadress. Kanske är den redan bytt?
        Annars kan det ha gått för lång tid sedan du bytte adress - du behöver
        bekräfta bytet via länk till din nya adress inom 30 minuter.
        Prova att logga in med din gamla epostadress och byt igen
        (denna länken fungerar bara en gång och är förbrukad). Går det fortfarande inte
        - kontakta support.`,
        redir: `Vidarebefordrar om ${countdown}
        `}
    }

    return (
        <>
            <Typography mt='10vh' variant='h5' textAlign="center" data-test="confirmation-message">{text().msg}</Typography>
            <Typography mt='2vh' variant='h5' textAlign="center">{text().redir}</Typography>
        </>
    )
}
