import { useContext, useRef, useState } from "react";
import { AlertColor, Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography } from "@mui/material";
import { useForm } from "../_helpers/useForm";
import { Relation, Respondent } from "../_interfaces";
import { modalStyle } from "../_styles/ModalStyle";
import { LoadingFormButton } from "./LoadingFormButton";
import { LanguageContext } from "../_contexts/LanguageContext";
import relationStrings from '../_strings/Relations.json';
import { api } from "../_services/apiServices";
import strings from "../_strings/AddRespondentModal.json";
import inputStrings from '../_strings/Input.json';

export function AddRespondentModal({ disabled, setNotification, updateStatus }: {
  disabled: boolean,
  updateStatus: () => void,
  setNotification: React.Dispatch<React.SetStateAction<{
    open: boolean,
    message: string,
    severity: AlertColor
  }>> }): JSX.Element {
  
    const { language } = useContext(LanguageContext);
    const nameRef = useRef<HTMLInputElement>();

    const { values, valid, handleInputValidation, handleInputValues, setValues } = useForm({
      email: { error: "", value:  "" },
      name: { error: "", value:  "" },
      relation: { error: "", value:  Relation.colleague },
      language: { error: "", value:  'swedish' }
    })
    const [open, setOpen] = useState(false);
    const handleOpen = (): void => {
      setOpen(true);
      setTimeout(() => {
        const nameField = nameRef.current;
        if (nameField) {
          setTimeout(() => nameField.focus(), 300);
        }        
      }, 50)
    }
    const handleClose = (): void => {
      setOpen(false);
      setValues(oldValues => ({
        email: { error: "", value:  "" },
        name: { error: "", value:  "" },
        relation: { error: "", value:  oldValues.relation.value },
        language: { error: "", value:  oldValues.language.value }
      }))
    };

    const handleSubmit = async( event ): Promise<boolean> =>
      api().addRespondent({
        name: values.name.value,
        email: values.email.value,
        relation: values.relation.value,
        language: values.language.value
      } as Respondent).then(({ errors, ...respondent }) => {
          if (respondent && !errors) {
            values.name.value = '';
            values.email.value = '';
            setNotification({ open: true, message: `Skickar mail till ${respondent.name}`, severity: 'success' })
            updateStatus();
            handleClose();
            return true;
          }
          setNotification({ open: true, message: `Kunde ej spara respondenten (${errors})`, severity: 'error' })
          return false;     
        }
        ).catch(error => {
          console.log(error)
          alert(`Lyckades inte lägga till ny respondent: ${error}`)
          return false;
        })
  
    return (
      <>
        <Button disabled={disabled} onClick={handleOpen} data-test="add-respondent-button" variant="contained" fullWidth sx={{ padding:'1vh', marginTop:'1vh' }} >+ {strings[language].addRespondent}</Button>
        <Modal
          open={open}
          data-test='add-respondent-modal'
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
            <Typography component='h1' variant="h5" mb="10px">
              {strings[language].addRespondentHeader}
            </Typography>
            <Typography>
              ({strings[language].addRespondentSubHeader})
            </Typography>
            <TextField 
              name='name'
              inputProps={{ 'data-test': 'name-input' }}
              label={inputStrings[language].name}
              margin='normal'
              inputRef={nameRef}
              disabled={disabled}
              value={values.name.value}
              helperText={<span style={{ color:"red" }}>{values.name.error}</span>}
              onChange={(e) => {
                handleInputValues(e);
                handleInputValidation(e);
              }}
              type='text'
              fullWidth
            />
            <TextField 
              name='email'
              inputProps={{ 'data-test': 'email-input' }}
              label={inputStrings[language].email}
              margin='normal'
              disabled={disabled}
              value={values.email.value}
              helperText={<span style={{ color:"red" }}>{values.email.error}</span>}
              onChange={(e) => {
                handleInputValues(e);
                handleInputValidation(e);
              }}
              type='email'
              fullWidth
            />
            <FormControl fullWidth margin='normal'>
                <InputLabel id='relationLabel'>{ inputStrings[language].relation }</InputLabel>
                <Select
                    id='relation'
                    name="relation"
                    labelId='relationLabel'
                    inputProps={{ "data-test": "relation-input" }}
                    value={values.relation.value || Relation.colleague}
                    disabled={disabled}
                    // onChange={handleInputValues}
                    onChange={(e) => handleInputValues(e)}
                    label={ inputStrings[language].relation }
                >
                    <MenuItem value={Relation.subordinate}>{ relationStrings[language].subordinate }</MenuItem>
                    <MenuItem value={Relation.colleague}>{ relationStrings[language].colleague }</MenuItem>
                    <MenuItem value={Relation.superior}>{ relationStrings[language].superior }</MenuItem>
                    <MenuItem value={Relation.other}>{ relationStrings[language].other }</MenuItem>
                </Select>
            </FormControl>
            {
                ['swedish', 'english'].length > 1 &&
                // <Grid item xs={12} sm={6} lg={2} margin='16px 0px 16px 0px'>
                    <FormControl fullWidth margin='normal'>
                        <InputLabel id='languagLabel'>{ inputStrings[language].language }</InputLabel>
                        <Select
                            id='language'
                            name="language"
                            labelId='languageLabel'
                            inputProps={{ "data-test": "language-input" }}
                            value={values.language.value}
                            disabled={disabled}
                            // onChange={handleInputValues}
                            onChange={(e) => handleInputValues(e)}
                            label={ inputStrings[language].language }
                        >
                            <MenuItem value="swedish">{ inputStrings[language].swedish }</MenuItem>
                            <MenuItem value="english">{ inputStrings[language].english }</MenuItem>
                        </Select>
                    </FormControl>
                // </Grid>    
            }

            <LoadingFormButton
              sx={{ padding:'1vh', marginTop:'16px', marginBottom:'8px' }}
              disabled={!valid}
              onClick={handleSubmit}
            >
              {inputStrings[language].add}
            </LoadingFormButton>
          </Box>
        </Box>
      </Modal>
    </>
    );
}