import { useCallback, useState } from "react"
import { checkEmailFormat, checkMaxLength, checkMinLength, checkPhoneFormat, checkPostalFormat, checkRequired, checkWeirdChars, constraints } from "./Constraints";

export const useForm = ( defaults: any, checkCallback?: any ): {
    handleInputValidation: (event: any, index?: number) => void
    handleInputValues: (event: any, index?: number) => void
    // error: string | undefined;
    valid: boolean
    // values: any []
    values: any
    setValues: any
} => {

    // const [error, setError] = useState<string | undefined>(undefined);
    const [valid, setValid] = useState(false);
    // const [values, setValues] = useState<any[]>(defaults || [])
    const [values, setValues] = useState<any>(defaults || {})

    const handleInputValues = useCallback((event, index?: number): void => {
    // const handleInputValues = (event, index?: number): void => {
        if (event.persist) {
            event.persist()
        }
        if (index !== undefined) {
            setValues(oldState => {
                const newState = [...oldState]
                if (index < newState.length) {
                    const valuePasted = Math.abs(
                        newState[index][event.target.name].value.length - event.target.value.length
                    ) > 1;
                    const stateWithNewValueAdded = { 
                        ...newState[index], 
                        [event.target.name]: { 
                            value: event.target.value, 
                            error: newState[index][event.target.name]?.error 
                        }
                    }
                    const validatedState = handleValidations<typeof stateWithNewValueAdded>(stateWithNewValueAdded)
                    newState[index] = { 
                        ...stateWithNewValueAdded, 
                        [event.target.name]: { 
                            value: stateWithNewValueAdded[event.target.name].value,
                            error: valuePasted ? validatedState[event.target.name].error : stateWithNewValueAdded[event.target.name].error
                        },
                        valid: validatedState.valid 
                    }
                    const nrOfValidEntries = newState.filter((v: any) => v.valid).length
                    if (nrOfValidEntries >= 2) {
                        setValid(true)
                    }
                }
                return newState;
                // return oldState.map((v,i) => {
                //     if (i === 0) {
                //         validCounter = 0;
                //     }
                //     if (i === index) {
                //         const valuePasted = Math.abs(v[event.target.name].value.length - event.target.value.length) > 1;
                //         const stateWithNewValueAdded = { ...v, [event.target.name]: { value: event.target.value, error: v[event.target.name]?.error } }
                //         const validatedState = handleValidations<typeof stateWithNewValueAdded>(stateWithNewValueAdded)
                //         validCounter = increaseValidCounter(validCounter, validatedState, oldState, i)
                //         if (valuePasted) {
                //             // Return validated state and new error message
                //             return { 
                //                 ...stateWithNewValueAdded, 
                //                 [event.target.name]: { 
                //                     value: stateWithNewValueAdded[event.target.name].value,
                //                     error: validatedState[event.target.name].error
                //                 },
                //                 valid: validatedState.valid 
                //             }
                //         }
                //         // Return validated state but keep old error message
                //         return { 
                //             ...stateWithNewValueAdded, 
                //             [event.target.name]: { 
                //                 value: stateWithNewValueAdded[event.target.name].value,
                //                 error: validatedState.valid ? null : stateWithNewValueAdded[event.target.name].error
                //             },
                //             valid: validatedState.valid 
                //         }
                //     }
                //     validCounter = increaseValidCounter(validCounter, v, oldState, i)
                //     return v;
                // })
            });
            // setValues(oldState => oldState.map((v,i) => {
            //     if (i === 0) {
            //         validCounter = 0;
            //     }
            //     if (i === index) {
            //         const valuePasted = Math.abs(v[event.target.name].value.length - event.target.value.length) > 1;
            //         const stateWithNewValueAdded = { ...v, [event.target.name]: { value: event.target.value, error: v[event.target.name]?.error } }
            //         const validatedState = handleValidations<typeof stateWithNewValueAdded>(stateWithNewValueAdded)
            //         validCounter = increaseValidCounter(validCounter, validatedState, oldState, i)
            //         if (valuePasted) {
            //             // Return validated state and new error message
            //             return { 
            //                 ...stateWithNewValueAdded, 
            //                 [event.target.name]: { 
            //                     value: stateWithNewValueAdded[event.target.name].value,
            //                     error: validatedState[event.target.name].error
            //                 },
            //                 valid: validatedState.valid 
            //             }
            //         }
            //         // Return validated state but keep old error message
            //         return { 
            //             ...stateWithNewValueAdded, 
            //             [event.target.name]: { 
            //                 value: stateWithNewValueAdded[event.target.name].value,
            //                 error: validatedState.valid ? null : stateWithNewValueAdded[event.target.name].error
            //             },
            //             valid: validatedState.valid 
            //         }
            //     }
            //     validCounter = increaseValidCounter(validCounter, v, oldState, i)
            //     return v;
            // }))
        }
        else {
            setValues(oldState => {
                const valuePasted = Math.abs((oldState[event.target.name].value?.length || 0) - (event.target.value?.length || 0)) > 1;
                const stateWithNewValueAdded = { ...oldState, [event.target.name]: { value: event.target.value, error: oldState[event.target.name]?.error } }
                if (valuePasted) {
                    const validatedState = handleValidations<typeof stateWithNewValueAdded>(stateWithNewValueAdded)
                    setValid(validatedState.valid)
                    return { 
                        ...stateWithNewValueAdded, 
                        [event.target.name]: { 
                            value: stateWithNewValueAdded[event.target.name].value,
                            error: validatedState[event.target.name].error
                        }
                    }
                }
                return stateWithNewValueAdded
            })
        }
    }, []);

    const handleValidations = <T>(value: any): T => {
        let newObject = { ...value };
        let noError = true;
        const keys = Object.keys(value)
        keys.forEach((key, index) => {
            const constraint = constraints[key]
            if (constraint) {
                const e = handleValidation(constraint, value[key].value) || checkCallback && checkCallback(key, value)
                // console.log('values in useForm', values)
                // const e = handleValidation(constraint, value[key].value) || checkCallback && checkCallback(key, value, values)
                if (e && constraint.required) {
                    noError = false;
                }
                newObject = { ...newObject, [key]: { ...newObject[key], error: e } }
            }
            if (index === keys.length - 1) {
                newObject = { ...newObject, valid: noError }
            }
        })
        return newObject;
    }

    /**
     * 
     * @param constraint 
     * @param value 
     * @returns undefined on success, error message on failure
     */
    const handleValidation = (constraint: any, value: string): string | undefined => {
        try {
            if (constraint.required) checkRequired(value, constraint.prettyText)
            if (constraint.min) checkMinLength(value, constraint.min, constraint.prettyText)
            if (constraint.max) checkMaxLength(value, constraint.max, constraint.prettyText)
            if (constraint.checkForWeirdChars) checkWeirdChars(value, constraint.prettyText)
            if (constraint.checkEmail) checkEmailFormat(value)
            if (constraint.checkPostal) checkPostalFormat(value)
            if (constraint.checkPhone) checkPhoneFormat(value)
        }
        catch (e: any) {
            return e.message
        }
        return undefined
    }

    const increaseValidCounter = (validCounter: number, newState: any, oldState: any, index: number): number => {
        let counter = validCounter
        if (newState.valid) {
            counter += 1
        }
        if (index === oldState.length - 1) {
            setValid(counter >= 2)
        }
        return counter;
    }

    const handleInputValidation = useCallback( ( event: any, index?: number ): void => {
        if (event.persist) {
            event.persist()
        }
        if (index !== undefined) {
            let validCounter = 0;
            setValues(oldState => oldState.map((v,i) => {
                if (i === 0) {
                    validCounter = 0;
                }
                if (i === index) {
                    const stateWithNewValueAdded = { ...v, [event.target.name]: { value: event.target.value, error: v[event.target.name]?.error } }
                    const validatedState = handleValidations<typeof stateWithNewValueAdded>(stateWithNewValueAdded)
                    validCounter = increaseValidCounter(validCounter, validatedState, oldState, i)
                    return { 
                        ...stateWithNewValueAdded, 
                        [event.target.name]: { 
                            value: stateWithNewValueAdded[event.target.name].value,
                            error: validatedState[event.target.name].error
                        },
                        valid: validatedState.valid 
                    }
                }
                validCounter = increaseValidCounter(validCounter, v, oldState, i)
                return v
            }))
        }
        else {
            setValues(oldState => {
                const stateWithNewValueAdded = { ...oldState, [event.target.name]: { value: event.target.value, error: oldState[event.target.name]?.error } }
                const validatedState = handleValidations<typeof stateWithNewValueAdded>(stateWithNewValueAdded)
                setValid(validatedState.valid)
                return { 
                    ...stateWithNewValueAdded, 
                    [event.target.name]: { 
                        value: stateWithNewValueAdded[event.target.name].value,
                        error: validatedState[event.target.name].error
                    }
                }
            })
        }
    }, []);

    return { handleInputValidation, handleInputValues, valid, values, setValues }
}

useForm.defaultProps = {
    required: false,
    min: undefined,
    max: undefined,
    checkForWeirdChars: undefined,
    checkEmail: undefined,
    checkPostal: undefined,
    checkPhone: undefined
}